<template>
    <TimeRule :videos="videos" @timeChange="onTimeChange" ref="timeRule"></TimeRule>
</template>

<script>
import TimeRule from "./timeRule";
import $ from "jquery";
import dayjs from "dayjs";
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter,);
export default {
    props: ["checkedCamera","alarmData"],
    components:{TimeRule},
    data(){
        return {
            timeValue:'',
            videos:[],
            video: null,
            timerange: [
                dayjs(dayjs().format("YYYYMMDD"), "YYYYMMDD").startOf('hour').toDate(),
                dayjs(dayjs().format("YYYYMMDD"), "YYYYMMDD").startOf('hour').toDate()
            ],
            videoLoading: false,
        }
    },
    watch:{
        video: function (newVal, oldVal) {
            if (newVal && newVal != oldVal) {
                this.startPlayback();
            } else {
                this.stopPlayback();
            }
        },
        videoLoading(){
            this.$emit('changeload',this.videoLoading);
        }
    },
    mounted(){
        if(this.alarmData){
            this.timeValue = dayjs(new Date(this.alarmData.alarm_time)).format("YYYY-MM-DD");
        }else{
            this.timeValue = dayjs(new Date()).format("YYYY-MM-DD");
        }
        this.getDate();
    },
    methods: {
        changeDate(date){
            this.timeValue = dayjs(date).format("YYYY-MM-DD");
            this.getDate();
        },
        getDate(){
            if(this.timeValue){
                this.videoLoading = true;
                this.timerange = [
                    dayjs(this.timeValue, "YYYYMMDD").startOf('hour').toDate(),
                    dayjs(this.timeValue, "YYYYMMDD").startOf('hour').toDate()
                ];
                this.getTimeVal();
            }
        },
        getTimeVal(){
            this.$nextTick(() => {
                let mmt;
                if(this.alarmData){
                    mmt = dayjs(this.alarmData.alarm_time)
                }else{
                    mmt = dayjs()
                }
                let n = mmt.diff(mmt.clone().startOf('day'), 'minutes');
                let playbackTime = this.checkedCamera.playback_interval ? this.checkedCamera.playback_interval : 5;
                n -= playbackTime;
                if (n < 0) n = 0;
                this.$refs.timeRule.clickMinute(n);
                this.getRecords(true);
            })
        },
        nextTimeRange() {
            var end = dayjs(this.timeValue, "YYYYMMDD").add(24, 'hours');
            var now = dayjs().startOf("second");
            if (end.isAfter(now, "second")) {
                end = now;
            }
            var r1 = dayjs(this.timerange[1]);
            if (r1.isSameOrAfter(end, "second")) {
                return false;
            }
            var r2 = dayjs(this.timerange[1]).add(6, 'hours');
            if (r2.isAfter(end)) {
                r2 = end;
            }
            if (r2.startOf("minute").isSameOrBefore(r1.startOf("minute"), "second")) {
                return false;
            }
            this.timerange = [r1.toDate(), r2.toDate()];
            return true;
        },
        onTimeChange(video){
            this.video = video;
        },
        getRecords(refresh) { //回放
            if (refresh) {
                this.records = [];
            }
            if (!this.nextTimeRange()) {
                this.videos = this.records;
                if(this.videos.length === 0){
                    this.videoLoading = false;
                }
                return
            }
            $.ajax(`${this.checkedCamera.movieurls}api/v1/playback/recordlist`, {
                    global: false,
                    data: {
                    timeout: 3,
                    serial: this.checkedCamera.national_num,
                    code: this.checkedCamera.channel,
                    // starttime: '2021-06-02T17:59:00',
                    // endtime: '2021-06-02T18:01:01',
                    starttime: dayjs(this.timerange[0]).format("YYYY-MM-DDTHH:mm:ss"),
                    endtime: dayjs(this.timerange[1]).format("YYYY-MM-DDTHH:mm:ss")
                }
            }).then(ret => {
                this.records = this.records.concat(ret.RecordList);
            }).always(() => {
                this.$nextTick(() => {
                    this.getRecords(false);
                })
            });
        },
        stopPlayback() {
            return new Promise((resolve, reject) => {
                if (this.touchTimer) {
                    clearInterval(this.touchTimer);
                    this.touchTimer = 0;
                }
                if (!this.streamID) {
                    resolve();
                    return
                }
                $.get(`${this.checkedCamera.movieurls}api/v1/playback/stop`, {
                    streamid: this.streamID
                }).always(() => {
                    this.streamID = "";
                    this.$emit('getVideoUrl','')
                    resolve();
                })
            })
        },
        async startPlayback() {
            await this.stopPlayback();
            if(!this.video) return;
            this.videoLoading = true;
            $.get(`${this.checkedCamera.movieurls}api/v1/playback/start`, {
                serial: this.checkedCamera.national_num,
                code: this.checkedCamera.channel,
                starttime: this.video.StartTime,
                endtime: this.video.EndTime
            }).then(res => {
                this.streamID = res.StreamID;
                this.hasAudio = res.AudioEnable && res.SourceAudioCodecName != "";
                this.$emit('getVideoUrl',this.$isIE() ? res.HLS : res.FLV);
            }).always(() => {
                this.videoLoading = false;
            });
        },
    }
}
</script>

<style lang="scss" scoped>

</style>