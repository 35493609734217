<template>
    <div class="playback">
        <a-spin :spinning="videoLoading" tip="加载中...">
            <div class="camera-screen" ref='cameraScreen'>
                <LivePlayer :videoUrl="videotapeUrl" live muted fluent :stretch="true" poster="1" alt="无画面">
                </LivePlayer>
            </div>
        </a-spin>
    </div>
</template>
<script>
import LivePlayer from '@liveqing/liveplayer';
export default {
    props: ["videotapeUrl",'videoLoading'],
    components: {LivePlayer},
    data(){
        return {
            // videotapeUrl:'',
        }
    },
    mounted(){
    },
    methods: {

    }
}
</script>
<style scoped lang="scss">
.playback{
    height:100%;
    position:relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    ::v-deep .ant-spin-nested-loading{
        width:100%;
    }
    .camera-screen{
        width:100%;
    }
}
.horizontal{
    position: absolute;
    width: 240px;
    top: -25px;
    left: 340px;
}
</style>