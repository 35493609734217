<template>
    <div class="camera-screen">
        <a-spin :spinning="videoLoading" tip="加载中...">
            <div class="camera-video">
                <LivePlayer :videoUrl="videoUrl" live muted stretch alt="无画面">
                </LivePlayer>
            </div>
        </a-spin>
    </div>
</template>
<script>
import LivePlayer from '@liveqing/liveplayer';
import {cameraAjax} from "@/api/index.js";
export default {
    props: ["checkedCamera"],
    components: {LivePlayer},
    data(){
        return {
            videoUrl: "",
            videoLoading:false,
        }
    },
    watch:{
        checkedCamera:{
            handler(val){
                if(val){
                    this.getCameraStream();
                }
            },
            deep:true,
            immediate:true,
        }
    },
    methods: {
        getCameraStream(){
            if(!this.checkedCamera){return}
            if(!this.checkedCamera.movieurls){
                this.$message.error("摄像头信息不全无法查看画面")
                return
            }
            this.videoLoading = true;
            cameraAjax.get(`${this.checkedCamera.movieurls}api/v1/stream/start`, {params:
                {
                    serial: this.checkedCamera.national_num,
                    code: this.checkedCamera.channel,
                }
            }).then(res => {
                this.videoUrl = this.$isIE() ? res.data.HLS : res.data.FLV;
                this.videoLoading = false;
            }).catch(() => {
                this.videoUrl = "";
                this.videoLoading = false;
            });
        },
    }
}
</script>
<style scoped lang="scss">
.camera-screen{
    width: 100%;
    // height:100%;
    margin: 0 auto;
    display: flex;
}
.camera-video{
    flex:1;
    ::v-deep .el-loading-mask{
      background-color: rgb(0, 0, 0) !important;
    }
}
</style>